import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

import Component from './DrawioComponent.vue'

export default Node.create({
    name: 'drawioComponent',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            url: {
                default: '',
            },
            id: {
                default: '',
            },
            styles: {

                default: {
                    imageWidth: 200,
                    imageHeight: 200,
                    imgJustifyContent: 'center',
                },
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'drawio-component',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        let ex = mergeAttributes(HTMLAttributes)
        console.log(ex);

        let margin = ''
        if (ex.styles.imgJustifyContent == 'center') {
            margin = 'margin: 0 auto'
        }
        if (ex.styles.imgJustifyContent == 'flex-start') {
            margin = 'margin: 0 auto 0 0'
        }
        if (ex.styles.imgJustifyContent == 'flex-end') {
            margin = 'margin: 0 0 0 auto'
        }

        let str = `width:${ex.styles.imageWidth}px;heigth:${ex.styles.imageHeight}px;display:block;${margin}`

        ex.src = ex.url
        ex.style=str
        return ['img', ex]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },
    addCommands() {
        return {
            // 添加命令来插入自定义节点
            insertdrawioComponent: (options) => ({ commands }) => {
                return commands.insertContent({
                    type: this.type.name, // 指向定义的节点类型
                    attrs: {
                        url: options.url,
                        id: options.id,
                        styles: {
                            imageWidth: options.styles.imageWidth,
                            imageHeight: options.styles.imageHeight,
                            imgJustifyContent: options.styles.imgJustifyContent,
                        }
                    },
                })
            },
        }
    },
})