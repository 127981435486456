import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

import Component from './WebViewComponent.vue'

export default Node.create({
    name: 'webViewComponent',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            url: {
                default: '',
            },
            id: {
                default: '',
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'iframe',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        let dom=document.createElement('iframe')
        dom.src=HTMLAttributes.url
        dom.url=HTMLAttributes.url
        dom.id=HTMLAttributes.id
        dom.style='width:100%;height:500px;'
        return dom
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },
    addCommands() {
        return {
            // 添加命令来插入自定义节点
            insertwebViewComponent: (options) => ({ commands }) => {
                return commands.insertContent({
                    type: this.type.name, // 指向定义的节点类型
                    attrs: {
                        url: options.url,
                        id: options.id,
                    },
                })
            },
        }
    },
})