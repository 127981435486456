<template>
  <node-view-wrapper class="code-block">
      <node-view-content  />
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  data() {
    return {
    };
  },

  computed: {

  },
  methods: {

  },
};
</script>

<style lang="scss">

</style>
