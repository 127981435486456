<template>
  <node-view-wrapper class="third_link_wrap">
    <div
      @mouseenter="onPopupEnter"
      @mouseleave="onPopupLeave"
      style="padding: 20px; position: relative"
    >
      <div
        v-if="showPopup"
        ref="popup"
        class="link-popup"
        style="position: absolute; top: -20px; left: 20px"
      >
        <div class="popup-content">
          <div class="link-bubble-menu">
            <div class="link_remove" @click="openLink">
              <el-tooltip
                :open-delay="1000"
                class="item"
                effect="dark"
                content="打开链接"
                placement="top-start"
              >
                <i class="iconfont icon-a-fenzu6"></i>
              </el-tooltip>
            </div>
            <div class="link_remove" @click="clearLink">
              <el-tooltip
                :open-delay="1000"
                class="item"
                effect="dark"
                content="移除"
                placement="top-start"
              >
                <i class="iconfont icon-a-huaban1111"></i>
              </el-tooltip>
            </div>

            <el-divider direction="vertical"></el-divider>

            <el-dropdown class="zdh-el-dropdown-link" trigger="click">
              <el-tooltip :open-delay="1000" content="视图" placement="bottom">
                <span
                  style="display: flex; align-items: center; font-size: 12px"
                >
                  网页视图
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </el-tooltip>
              <el-dropdown-menu slot="dropdown" class="zdh-dropdown-bubble">
                <el-dropdown-item>
                  <div class="zdh-dropdown-item" @click="text_operate()">
                    <i class="iconfont icon-a-huaban110"> </i>
                    <div class="name">链接视图</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="zdh-dropdown-item" @click="card_operate()">
                    <i class="iconfont icon-a-huaban110"> </i>
                    <div class="name">卡片视图</div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <iframe
        style="width: 100%; height: 500px"
        :src="node.attrs.url"
        frameborder="0"
      ></iframe>
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,
  data() {
    return {
      keepPopupVisible: false,
      showPopup: false,
    };
  },

  computed: {},
  mounted() {},
  methods: {
    card_operate() {
      let ppp = this.clearLink();
      this.editor
        .chain()
        .focus()
        .insertContentAt(ppp, {
          type: "cardViewComponent",
          attrs: {
            url: this.node.attrs.url,
            id: this.node.attrs.id,
          },
        })
        .run();
    },
    insertLinkAtPosition(pos, url) {
      // 获取URL的标题
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          const title = html.match(/<title>(.*?)<\/title>/i);
          const linkText = title ? title[1] : url;
          const tr = this.editor.view.state.tr;
          tr.insertText(linkText, pos);
          const linkMark = this.editor.view.state.schema.marks.link.create({
            href: url,
          });
          tr.addMark(pos, pos + linkText.length + 1, linkMark);
          this.editor.view.dispatch(tr);
        })
        .catch((err) => {
          const title = url;
          const linkText = title ? title[1] : url;
          const tr = this.editor.view.state.tr;
          tr.insertText(linkText, pos);
          const linkMark = this.editor.view.state.schema.marks.link.create({
            href: url,
          });
          tr.addMark(pos, pos + linkText.length + 1, linkMark);
          this.editor.view.dispatch(tr);
        });
    },

    text_operate() {
      let pp = 0;
      const nodes = this.editor.$nodes("webViewComponent");
      for (let i of nodes) {
        if (i.currentNode.attrs.id == this.node.attrs.id) {
          pp = i.from;
          this.editor.commands.deleteRange({ from: i.from, to: i.to });
        }
      }
      this.insertLinkAtPosition(pp, this.node.attrs.url);
      //   this.editor.commands.setLink({ href: "https://example.com" });
    },
    clearLink() {
      const nodes = this.editor.$nodes("webViewComponent");
      for (let i of nodes) {
        if (i.currentNode.attrs.id == this.node.attrs.id) {
          this.editor.commands.deleteRange({ from: i.from, to: i.to });
          return i.from;
        }
      }
    },
    openLink() {
      window.open(this.node.attrs.url);
    },
    onPopupEnter() {
      if (this.editor.isEditable) {
        this.showPopup = true;
        this.keepPopupVisible = true;
      }
    },
    onPopupLeave() {
      if (this.editor.isEditable) {
        this.keepPopupVisible = false;
        setTimeout(() => {
          if (!this.keepPopupVisible) {
            this.showPopup = false;
          }
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss">
.link-popup {
  border-radius: 3px;
  background: white;
  border: 1px solid #ddd;
  padding: 4px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.link-bubble-menu {
  display: flex;
  align-items: center;
  .link-input {
    border: none;
    width: 300px;
    border-bottom: 1px solid #dfe1e5;
  }
  .save_link {
    font-size: 12px;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background: #f7f8fa;
    }
  }
  .link-text {
    // margin-right: 5px;

    padding-left: 5px;
    max-width: 200px;
    color: #606266;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .iconfont {
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background: #f7f8fa;
    }
  }
}
.third_link_wrap {
  .web_view_list {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
  }
}
</style>
