// constants.js
const emojis = [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🥸",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "☹️",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥺",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🤭",
    "🤫",
    "🤥",
    "😶",
    "😐",
    "😑",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "🥱",
    "😴",
    "🤤",
    "😪",
    "😵"
];

const defult_back_color = [
    [
        "#f5f5f5", // 纯白
        "#d5d5d5", // 浅灰
        "#aaaaaa", // 中灰
        "#808080", // 灰色
        "#555555", // 深灰
        "#2b2b2b", // 接近黑色
        "#000000"  // 纯黑
    ],
    [
        "#ff9e66",
        "#ffbf80",
        "#ffeb99",
        "#d9f7d9",
        "#a3ffe9",
        "#a9d5ff",
        "#ffb6e0"
    ],
    [
        "#cc3700",
        "#cc7000",
        "#ccac00",
        "#73bb73",
        "#009ea1",
        "#1873cc",
        "#9f0f68"
    ]
];

const defult_font_color = [
    [
        "#f5f5f5", // 纯白
        "#d5d5d5", // 浅灰
        "#aaaaaa", // 中灰
        "#808080", // 灰色
        "#555555", // 深灰
        "#2b2b2b", // 接近黑色
        "#000000"  // 纯黑
    ],
    [
        "#ff9e66",
        "#ffbf80",
        "#ffeb99",
        "#d9f7d9",
        "#66e9eb",
        "#80c1ff",
        "#f280c1"
    ],
    [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585"
    ],
    [
        "#cc3700",
        "#cc7000",
        "#ccac00",
        "#73bb73",
        "#009ea1",
        "#1873cc",
        "#9f0f68"
    ]
];

const predefineColors = [
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#90ee90",
    "#00ced1",
    "#1e90ff",
    "#c71585",
    "rgba(255, 69, 0, 0.68)",
    "rgb(255, 120, 0)",
    "hsv(51, 100, 98)",
    "hsva(120, 40, 94, 0.5)",
    "hsl(181, 100%, 37%)",
    "hsla(209, 100%, 56%, 0.73)",
    "#c7158577"
];

const headingOptions = [
    { label: "h1", value: 1 },
    { label: "h2", value: 2 },
    { label: "h3", value: 3 },
    { label: "h4", value: 4 },
    { label: "h5", value: 5 },
    { label: "h6", value: 6 }
];

export default {
    emojis,
    defult_back_color,
    defult_font_color,
    predefineColors,
    headingOptions
};