import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

import Component from './CardViewComponent.vue'

export default Node.create({
    name: 'cardViewComponent',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            url: {
                default: '',
            },
            id: {
                default: '',
            },
            data:{
                default: {
                    title:'',
                    desc:''
                },
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'vue-component',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        let ex = mergeAttributes(HTMLAttributes)

        const url = ex.url
        const title = ex.data.title
        const desc = ex.data.desc;
      
        // 创建外层 div
        const card = document.createElement("div");
        card.className = "card_view_2025";
      
        // 创建链接
        const link = document.createElement("a");
        link.className = "url";
        link.href = url;
        link.target = "_blank";
        link.textContent = url;
      
        // 创建标题
        const titleDiv = document.createElement("div");
        titleDiv.className = "title";
        titleDiv.style.fontWeight = "700";
        titleDiv.style.fontSize = "larger";
        titleDiv.textContent = title;
      
        // 创建描述
        const descDiv = document.createElement("div");
        descDiv.className = "desc";
        
        if (desc && desc.length > 0) {
          descDiv.textContent = desc;
        } else {
          descDiv.style.display = "none"; // 隐藏空描述
        }
      
        // 组装结构
        card.appendChild(link);
        card.appendChild(titleDiv);
        card.appendChild(descDiv);
        return card
        // return ['vue-component', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },
    addCommands() {
        return {
            // 添加命令来插入自定义节点
            insertcardViewComponent: (options) => ({ commands }) => {
                return commands.insertContent({
                    type: this.type.name, // 指向定义的节点类型
                    attrs: {
                        url: options.url,
                        id: options.id,
                    },
                })
            },
        }
    },
})